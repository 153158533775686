1
<template>
  <svg height="130px" viewBox="0 0 785 130" width="785px" xmlns="http://www.w3.org/2000/svg">
    <!-- Generator: Sketch 61.2 (89653) - https://sketch.com -->
    <desc>Created with Sketch.</desc>
    <g id="il-avoin-data-logo-rgb" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
      <g transform="translate(2.000000, 1.000000)">
        <path
          id="fill-08"
          d="M542.7506,69.1879 C545.4376,69.1879 548.2266,69.2419 551.1206,69.3429 C554.0126,69.4469 556.8026,69.4979 559.4906,69.4979 C562.3826,69.4979 565.2766,69.4739 568.1706,69.4199 C571.0626,69.3699 573.9566,69.2919 576.8516,69.1879 L559.6466,29.8179 L542.7506,69.1879 Z M611.4156,117.3929 C609.9676,117.1849 608.5996,117.0839 607.3086,117.0839 L603.2006,117.0839 L599.0936,117.0839 C597.8006,117.0839 596.4316,117.1849 594.9856,117.3929 C594.2616,115.0169 593.2806,112.1499 592.0406,108.7909 C590.8006,105.4329 589.4296,101.9189 587.9336,98.2499 C586.4346,94.5839 584.9106,90.8639 583.3606,87.0899 C581.8106,83.3199 580.3626,79.7279 579.0216,76.3179 C575.7126,76.2159 572.3546,76.1379 568.9446,76.0849 C565.5356,76.0349 562.1256,76.0069 558.7156,76.0069 C555.5116,76.0069 552.3606,76.0349 549.2616,76.0849 C546.1606,76.1379 543.0076,76.2159 539.8066,76.3179 C536.4976,84.3779 533.5016,92.0499 530.8156,99.3349 C528.1276,106.6209 526.1126,112.6409 524.7716,117.3929 C523.0126,117.1849 521.1016,117.0839 519.0356,117.0839 C516.9676,117.0839 515.0566,117.1849 513.3006,117.3929 C521.6706,99.3119 529.8316,81.3319 537.7906,63.4529 C545.7466,45.5769 553.7556,27.3909 561.8156,8.8929 L564.7616,8.8929 C567.2406,14.8869 569.9266,21.3709 572.8196,28.3459 C575.7126,35.3199 578.6576,42.3729 581.6556,49.5029 C584.6516,56.6329 587.6476,63.7119 590.6466,70.7379 C593.6426,77.7659 596.4586,84.2999 599.0936,90.3449 C601.7286,96.3899 604.1046,101.7919 606.2236,106.5419 C608.3396,111.2969 610.0716,114.9139 611.4156,117.3929 L611.4156,117.3929 Z"
          fill="#ffffff"></path>
        <path
          id="fill-07"
          d="M640.402,18.9676 C637.713,18.9676 634.899,18.9946 631.954,19.0456 C629.009,19.0986 626.064,19.2006 623.119,19.3556 C620.173,19.5106 617.331,19.6926 614.593,19.8976 C611.855,20.1066 609.402,20.3626 607.232,20.6726 C607.748,18.8126 608.006,17.1606 608.006,15.7126 C608.006,14.5766 607.748,12.9756 607.232,10.9076 C614.051,11.1156 620.716,11.2956 627.226,11.4506 C633.736,11.6056 640.402,11.6826 647.221,11.6826 C654.042,11.6826 660.707,11.6056 667.216,11.4506 C673.726,11.2956 680.391,11.1156 687.212,10.9076 C686.693,12.9756 686.437,14.6276 686.437,15.8676 C686.437,17.2116 686.693,18.8126 687.212,20.6726 C685.042,20.3626 682.586,20.1066 679.849,19.8976 C677.11,19.6926 674.269,19.5106 671.324,19.3556 C668.379,19.2006 665.434,19.0986 662.489,19.0456 C659.545,18.9946 656.727,18.9676 654.042,18.9676 C653.732,29.6126 653.576,40.3066 653.576,51.0526 L653.576,77.7126 C653.576,84.5326 653.705,91.2516 653.965,97.8626 C654.22,104.4766 654.506,110.9876 654.816,117.3926 C652.438,117.1846 649.908,117.0836 647.221,117.0836 C644.533,117.0836 642.003,117.1846 639.627,117.3926 C639.937,110.9876 640.22,104.4766 640.479,97.8626 C640.736,91.2516 640.867,84.5326 640.867,77.7126 L640.867,51.0526 C640.867,40.3066 640.712,29.6126 640.402,18.9676"
          fill="#ffffff"></path>
        <path
          id="fill-06"
          d="M712.4781,69.1879 C715.1641,69.1879 717.9541,69.2419 720.8471,69.3429 C723.7401,69.4469 726.5291,69.4979 729.2181,69.4979 C732.1091,69.4979 735.0031,69.4739 737.8981,69.4199 C740.7891,69.3699 743.6831,69.2919 746.5781,69.1879 L729.3731,29.8179 L712.4781,69.1879 Z M781.1421,117.3929 C779.6951,117.1849 778.3261,117.0839 777.0351,117.0839 L772.9271,117.0839 L768.8201,117.0839 C767.5271,117.0839 766.1581,117.1849 764.7121,117.3929 C763.9881,115.0169 763.0071,112.1499 761.7671,108.7909 C760.5271,105.4329 759.1571,101.9189 757.6601,98.2499 C756.1621,94.5839 754.6381,90.8639 753.0871,87.0899 C751.5381,83.3199 750.0891,79.7279 748.7481,76.3179 C745.4391,76.2159 742.0821,76.1379 738.6721,76.0849 C735.2631,76.0349 731.8531,76.0069 728.4431,76.0069 C725.2381,76.0069 722.0871,76.0349 718.9881,76.0849 C715.8881,76.1379 712.7341,76.2159 709.5331,76.3179 C706.2241,84.3779 703.2281,92.0499 700.5421,99.3349 C697.8551,106.6209 695.8391,112.6409 694.4981,117.3929 C692.7401,117.1849 690.8291,117.0839 688.7631,117.0839 C686.6951,117.0839 684.7831,117.1849 683.0271,117.3929 C691.3981,99.3119 699.5591,81.3319 707.5171,63.4529 C715.4741,45.5769 723.4821,27.3909 731.5421,8.8929 L734.4881,8.8929 C736.9681,14.8869 739.6541,21.3709 742.5471,28.3459 C745.4391,35.3199 748.3841,42.3729 751.3821,49.5029 C754.3781,56.6329 757.3741,63.7119 760.3731,70.7379 C763.3691,77.7659 766.1851,84.2999 768.8201,90.3449 C771.4551,96.3899 773.8321,101.7919 775.9511,106.5419 C778.0671,111.2969 779.7981,114.9139 781.1421,117.3929 L781.1421,117.3929 Z"
          fill="#ffffff"></path>
        <path
          id="fill-05"
          d="M424.9498,77.7123 C424.9498,88.8723 425.2598,99.8273 425.8808,110.5733 C428.0508,111.0913 430.9948,111.4793 434.7148,111.7353 C438.4348,111.9943 442.2568,112.1223 446.1848,112.1223 C451.8668,112.1223 457.5508,111.4523 463.2348,110.1083 C468.9168,108.7663 474.0078,106.2333 478.5028,102.5133 C482.9978,98.7923 486.6638,93.7043 489.5078,87.2453 C492.3478,80.7883 493.7698,72.4423 493.7698,62.2123 C493.7698,53.2233 492.6578,45.8363 490.4368,40.0473 C488.2148,34.2623 485.1678,29.6893 481.2928,26.3303 C477.4178,22.9733 472.7918,20.6493 467.4198,19.3553 C462.0468,18.0643 456.1558,17.4183 449.7498,17.4183 C445.0988,17.4183 440.7598,17.5733 436.7298,17.8823 C432.6998,18.1923 429.0818,18.5563 425.8808,18.9673 C425.5698,23.9283 425.3378,29.1203 425.1818,34.5453 C425.0268,39.9703 424.9498,45.4733 424.9498,51.0533 L424.9498,77.7123 Z M412.2398,51.0533 C412.2398,44.2333 412.1088,37.5443 411.8528,30.9803 C411.5938,24.4193 411.3098,17.7283 410.9998,10.9083 C414.9258,11.1163 418.8278,11.2953 422.7028,11.4503 C426.5778,11.6053 430.4758,11.6833 434.4048,11.6833 C438.3308,11.6833 442.2318,11.5543 446.1068,11.2953 C449.9818,11.0383 453.8818,10.9083 457.8098,10.9083 C466.9018,10.9083 474.6788,12.0973 481.1378,14.4733 C487.5948,16.8513 492.8898,20.2343 497.0248,24.6253 C501.1568,29.0183 504.1788,34.3903 506.0928,40.7453 C508.0038,47.1003 508.9608,54.2573 508.9608,62.2123 C508.9608,72.5473 507.1778,81.2533 503.6128,88.3303 C500.0478,95.4093 495.4998,101.1183 489.9718,105.4583 C484.4428,109.7983 478.3468,112.8743 471.6818,114.6813 C465.0168,116.4893 458.6368,117.3933 452.5408,117.3933 C449.0258,117.3933 445.5648,117.3393 442.1548,117.2373 C438.7448,117.1343 435.2818,117.0833 431.7698,117.0833 L421.3848,117.0833 C417.9738,117.0833 414.5118,117.1853 410.9998,117.3933 C411.3098,110.9873 411.5938,104.4773 411.8528,97.8623 C412.1088,91.2513 412.2398,84.5323 412.2398,77.7123 L412.2398,51.0533 Z"
          fill="#ffffff"></path>
        <path
          id="fill-04"
          d="M320.8834,27.9999 C300.9514,27.9999 284.7364,44.2159 284.7364,64.1479 C284.7364,84.0799 300.9514,100.2949 320.8834,100.2949 C340.8164,100.2949 357.0314,84.0799 357.0314,64.1479 C357.0314,44.2159 340.8164,27.9999 320.8834,27.9999 M320.8834,128.2949 C285.5124,128.2949 256.7364,99.5189 256.7364,64.1479 C256.7364,28.7759 285.5124,-0.0001 320.8834,-0.0001 C356.2544,-0.0001 385.0314,28.7759 385.0314,64.1479 C385.0314,99.5189 356.2544,128.2949 320.8834,128.2949"
          fill="#63B9E9"></path>
        <path
          id="fill-03"
          d="M216.1461,30.9803 C215.8861,24.4193 215.6031,17.7283 215.2921,10.9083 C216.6341,11.1163 217.9271,11.2953 219.1671,11.4503 C220.4081,11.6053 221.6481,11.6833 222.8881,11.6833 C224.1281,11.6833 225.3681,11.6053 226.6081,11.4503 C227.8471,11.2953 229.1381,11.1163 230.4831,10.9083 C230.1731,17.7283 229.8871,24.4193 229.6301,30.9803 C229.3711,37.5433 229.2431,44.2323 229.2431,51.0533 L229.2431,77.7123 C229.2431,84.5323 229.3711,91.2513 229.6301,97.8623 C229.8871,104.4773 230.1731,110.9873 230.4831,117.3933 C228.1051,117.1853 225.5741,117.0833 222.8881,117.0833 C220.2001,117.0833 217.6681,117.1853 215.2921,117.3933 C215.6031,110.9873 215.8861,104.4773 216.1461,97.8623 C216.4021,91.2513 216.5331,84.5323 216.5331,77.7123 L216.5331,51.0533 C216.5331,44.2323 216.4021,37.5433 216.1461,30.9803"
          fill="#ffffff"></path>
        <path
          id="fill-02"
          d="M110.2809,51.6727 C117.5659,65.5207 125.1849,79.7277 133.1439,94.2977 C134.1759,92.3367 135.6759,89.5187 137.6379,85.8507 C139.6009,82.1827 141.8749,77.9717 144.4589,73.2177 C147.0409,68.4657 149.8059,63.3247 152.7519,57.7957 C155.6969,52.2687 158.6929,46.6617 161.7419,40.9777 C164.7889,35.2967 167.8099,29.7157 170.8079,24.2377 C173.8039,18.7617 176.5949,13.6467 179.1789,8.8927 L181.1939,8.8927 C182.3299,18.8127 183.3879,28.3987 184.3709,37.6457 C185.3519,46.8947 186.3599,55.9887 187.3939,64.9247 C188.4259,73.8647 189.4599,82.6997 190.4939,91.4307 C191.5249,100.1637 192.6639,108.8177 193.9039,117.3927 C192.6639,117.1847 191.4489,117.0837 190.2609,117.0837 L186.6189,117.0837 L182.9759,117.0837 C181.7869,117.0837 180.5739,117.1847 179.3339,117.3927 C178.1949,103.6517 177.0599,90.3217 175.9239,77.4027 C174.7859,64.4877 173.3899,51.3117 171.7389,37.8777 L171.4289,37.8777 C164.2989,50.8977 157.2979,63.8397 150.4269,76.7057 C143.5529,89.5697 137.0189,103.0307 130.8189,117.0837 L129.4239,117.0837 C123.2239,104.0627 116.7379,91.0187 109.9709,77.9447 C103.2029,64.8747 96.4089,51.5177 89.5899,37.8777 L89.2789,37.8777 C88.5539,44.2857 87.7539,51.2347 86.8769,58.7257 C85.9979,66.2177 85.1949,73.6047 84.4739,80.8897 C83.7499,88.1747 83.1309,95.0477 82.6139,101.5057 C82.0949,107.9647 81.8399,113.2607 81.8399,117.3927 C80.1849,117.1847 78.3759,117.0837 76.4139,117.0837 C74.4499,117.0837 72.6399,117.1847 70.9889,117.3927 C72.3309,109.0227 73.7259,100.1877 75.1739,90.8877 C76.6199,81.5877 78.0149,72.2377 79.3589,62.8337 C80.7009,53.4307 81.9409,44.1547 83.0789,35.0107 C84.2149,25.8657 85.1949,17.1607 86.0239,8.8927 L88.6589,8.8927 C95.7889,23.5677 102.9959,37.8267 110.2809,51.6727"
          fill="#ffffff"></path>
        <path
          id="fill-01"
          d="M0.8512,30.9803 C0.5922,24.4193 0.3082,17.7283 -0.0008,10.9083 C4.6492,11.1163 9.2722,11.2953 13.8712,11.4503 C18.4682,11.6053 23.0932,11.6833 27.7442,11.6833 C32.3942,11.6833 37.0952,11.6053 41.8492,11.4503 C46.6012,11.2953 51.7692,11.1163 57.3492,10.9083 C57.0392,12.4583 56.8842,14.0073 56.8842,15.5583 C56.8842,17.1073 57.0392,18.6583 57.3492,20.2083 C54.5582,19.8973 51.2002,19.6143 47.2742,19.3553 C43.3452,19.0983 39.3422,18.8903 35.2612,18.7353 C31.1782,18.5803 27.2512,18.4523 23.4822,18.3483 C19.7082,18.2463 16.5832,18.1923 14.1042,18.1923 C13.9992,21.8113 13.9222,25.2723 13.8712,28.5783 C13.8182,31.8863 13.7942,35.1923 13.7942,38.4983 C13.7942,42.1163 13.8182,45.4483 13.8712,48.4953 C13.9222,51.5443 13.9492,54.7223 13.9492,58.0273 C20.7692,58.0273 27.5112,57.9263 34.1762,57.7173 C40.8412,57.5123 47.4292,57.0983 53.9392,56.4783 C53.5252,58.0273 53.3192,59.5773 53.3192,61.1283 C53.3192,62.6783 53.5252,64.2283 53.9392,65.7773 C51.3552,65.4683 48.4372,65.2353 45.1812,65.0803 C41.9262,64.9253 38.5402,64.7973 35.0292,64.6923 C31.5152,64.5913 27.9502,64.5383 24.3332,64.5383 L13.9492,64.5383 L13.9492,77.7123 C13.9492,84.5323 14.0782,91.2513 14.3362,97.8623 C14.5932,104.4773 14.8782,110.9873 15.1892,117.3933 C12.8102,117.1853 10.2802,117.0833 7.5932,117.0833 C4.9062,117.0833 2.3742,117.1853 -0.0008,117.3933 C0.3082,110.9873 0.5922,104.4773 0.8512,97.8623 C1.1082,91.2513 1.2392,84.5323 1.2392,77.7123 L1.2392,51.0533 C1.2392,44.2323 1.1082,37.5433 0.8512,30.9803"
          fill="#ffffff"></path>
      </g>
    </g>
  </svg>
</template>
